import * as style from '../../styles/layouts/productPage.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Head from '../Head';
import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import LastUpdate from '../shared/LastUpdate';

interface Props {
  children: React.ReactNode;
  header?: string;
  subHeader?: string;
  color?: string;
  backLink: string;
  copy: string;
}

export default function LayoutProductPage({
  children,
  header,
  subHeader,
  backLink = '/',
  color = 'green',
  copy = '',
}: Props) {
  return (
    <section>
      {/* <LastUpdate /> */}
      <section className={classnames(style.carousel, style[color])}>
        <div className={gstyle.center}>
          <div className={style.head}>
            <Head bigLogo={true} color={color} fixed={true}></Head>
            <Head bigLogo={true} color={color}></Head>
          </div>
          <div className={style.navigation}>
            <div className={style.arrowBack}>
              <Link to={backLink}>
                <img src="/assets/icons/arrow-left.svg" alt="Back to Index Page" />
              </Link>
            </div>
            <h1>
              {header} <span>{subHeader ? `  |  ` + subHeader : ''}</span>
            </h1>
            <div className={style.arrowBack}></div>
          </div>
          {/* TODO HAVE THIS PERES AS VARIABLE (LIKE header) */}
          <p className={style.mainCopy}>{copy}</p>
        </div>
      </section>
      {children}
    </section>
  );
}
