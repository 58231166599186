import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useProductsMetadataCs } from '../hooks/cs/products-metadata';
import { useProductsMetadataEn } from '../hooks/en/products-metadata.en';

export function ProductsMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useProductsMetadataCs();
  } else {
    data = useProductsMetadataEn();
  }

  data.nodes.sort((a: any, b: any) => {
    return a.produkt.order - b.produkt.order;
  });

  return data;
}
