import { useStaticQuery, graphql } from 'gatsby';
export const useProductsMetadataCs = () => {
  const data = useStaticQuery(graphql`
    query Products {
      allWpProduct: allWpPage(
        filter: { wpParent: { node: { uri: { eq: "/produkty/" } } }, parent: {} }
      ) {
        nodes {
          title
          contentTypeName
          produkt {
            hlavniPopis
            nazev
            perex
            popis
            order
            barva
            image {
              sourceUrl
            }
            logo {
              sourceUrl
            }
            productLink
          }
        }
      }
    }
  `);
  return data?.allWpProduct;
};
