// extracted by mini-css-extract-plugin
export var blue = "productIndex-module--blue--4cewi";
export var button = "productIndex-module--button--ZntGn";
export var center = "productIndex-module--center--9Acd-";
export var copy = "productIndex-module--copy--E6m+x";
export var darkorange = "productIndex-module--darkorange--XzFlb";
export var green = "productIndex-module--green--GpCyE";
export var image = "productIndex-module--image--A+8Te";
export var orange = "productIndex-module--orange--S7wBr";
export var product = "productIndex-module--product--Olbya";
export var productDetail = "productIndex-module--productDetail--0M2i2";
export var productList = "productIndex-module--productList--H1JnL";
export var purple = "productIndex-module--purple--dMwY+";