import LayoutProductPage from '../../components/layouts/ProductPage';
import * as style from '../../styles/pages/productIndex.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Footer from '../../components/Footer';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { ProductsMetadata } from '../../data/products.data';
import { ProductPageMetadata } from '../../data/page-product.data';
import { commonlocalesData } from '../../data/commonlocales.data';

export default function index() {
  const { nodes } = ProductsMetadata();
  const product = ProductPageMetadata();
  const locales = commonlocalesData();
  return (
    <section>
      <Helmet title="TALXIS Produkty"> </Helmet>
      <LayoutProductPage backLink="/" header={product.title} copy={product.copy}>
        <section className={style.productList}>
          {nodes.map((product: any) => (
            <section
              key={product.id}
              className={classnames(style.product, style[product.produkt.barva])}
            >
              <div className={classnames(gstyle.center, style.center)}>
                <div
                  className={style.image}
                  style={{ backgroundImage: `url(${product.produkt.image.sourceUrl})` }}
                ></div>
                <div className={style.copy}>
                  <h1>
                    <img src={product.produkt.logo.sourceUrl} alt="logo" width={85} height={85} />
                    TALXIS <br />
                    {product.produkt.nazev}
                  </h1>
                  <p>{product.produkt.hlavniPopis}</p>
                  <p>{product.produkt.perex}</p>
                </div>
                <div className={style.button}>
                  <Link to={product.produkt.productLink}>{locales.zobrazitvice}</Link>
                </div>
              </div>
            </section>
          ))}
        </section>
      </LayoutProductPage>
      <section className={style.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
