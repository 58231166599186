// extracted by mini-css-extract-plugin
export var arrowBack = "productPage-module--arrowBack--JwEKI";
export var blue = "productPage-module--blue--jzITB";
export var carousel = "productPage-module--carousel--ewBEP";
export var darkorange = "productPage-module--darkorange--zRyLc";
export var green = "productPage-module--green--aaRSq";
export var head = "productPage-module--head--Igsma";
export var mainCopy = "productPage-module--mainCopy--QwFot";
export var navigation = "productPage-module--navigation--safhH";
export var orange = "productPage-module--orange--Iv3gX";
export var purple = "productPage-module--purple--L7AwX";