import { useStaticQuery, graphql } from 'gatsby';
export const useProductPageMetaDataEn = () => {
  const site = useStaticQuery(graphql`
    query ProductPageEn {
      wpPage(id: { eq: "cG9zdDo5OTc=" }) {
        produktLayout {
          title
          copy
        }
      }
    }
  `);
  return site?.wpPage;
};
