import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useProductPageMetaDataCs } from '../hooks/cs/page-product.metadata';
import { useProductPageMetaDataEn } from '../hooks/en/page-product.metadata.en';

export function ProductPageMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useProductPageMetaDataCs();
  } else {
    data = useProductPageMetaDataEn();
  }
  return data.produktLayout;
}
